import React, { useContext } from 'react'
import Buttons from '../buttons/buttons'
import { Context } from '../context/provider'
import styles from './tagRenewalHome.module.scss'
import { navigate } from 'gatsby'

export default function TagRenewalHome() {
  const context = useContext(Context)

  function handleClick() {
    const setCurrent = context?.setCurrent

    setCurrent(0)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img className={styles.plate} src="/plate.png" alt="" />
        <h1>Muscogee (Creek) Nation Tag Renewal</h1>
        <h2>Before we get started, please ensure you have these items:</h2>
        <ul>
          <li>Driver's license</li>
          <li>Citizen roll number</li>
          <li>VIN and plate number</li>
        </ul>
        <Buttons {...{
          handleBack: () => navigate('/'),
          handleNext: handleClick,
          nextLabel: `Let's get started`,
          backLabel: 'BACK'
        }} />
      </div>
    </section>
  )
}