import React, { useState, useContext } from 'react'
import { Context } from '../context/provider'
import styles from './insurance.module.scss'
import Buttons from '../buttons/buttons'
import PropTypes from 'prop-types'

const DisplayItem = ({ showIf, label, value }) => {
  if (!showIf) {
    return null
  }

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{value}</div>
      <div className={styles.check}><img src="/check.svg" alt="" /></div>
    </div>
  )
}

export default function Insurance() {
  const entries = typeof window !== 'undefined' ? window.sessionStorage : 'undefined'
  const verified = entries.verified === "true"
  const [message, setMessage] = useState("")

  const context = useContext(Context)

  function handleNext() {
    const setCurrent = context?.setCurrent
    if (window.sessionStorage.getItem("verified") === "true") {
      setCurrent(3)
    } else
      setMessage("Valid insurance must be verified.")  
  }

  function handleBack() {
    const setCurrent = context?.setCurrent
    setCurrent(1)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
      <h2>Insurance Verification</h2>
        <DisplayItem {...{
          showIf: !!entries.policy && verified,
          label: 'Policy number',
          value: entries.policy,
        }} />
        
        <DisplayItem {...{
          showIf: !!entries.naic && verified,
          label: 'NAIC number',
          value: entries.naic,
        }} />
        
        <DisplayItem {...{
          showIf: verified,
          label: 'Status',
          value: 'Confirmed',
        }} />
       
        {!verified && 
        <div className={styles.entry}>
          <div className={styles.key}>Status</div>
          <div className={styles.value + ` ${styles.message}`}>
            {message || entries.reasonCode || "Insurance not verified"}
          </div>
          <div className={styles.x}>x</div>
        </div>}
     
        <Buttons {...{
          handleBack,
          handleNext,
          backLabel: 'BACK',
          nextLabel: 'PERSONAL INFORMATION'
        }} />
      </div>
    </section>
  )
}

DisplayItem.propTypes = {
  showIf: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
}
