import React, { useContext } from 'react'
import axios from 'axios'
import styles from './summary.module.scss'
import { Context } from '../context/provider'
import { useSiteConfig } from '../layout/getSiteConfig'
import { usePostError } from '../../utilities/usePostError';
import Buttons from '../buttons/buttons';
import { getTransactionId } from '../../utilities/getTransactionId'
import PropTypes from 'prop-types'

const DisplayItem = ({ value, label, display }) => {
  if (!value) {
    return null
  }

  const displayVal = display ? display(value) : value

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{displayVal}</div>
      <div className={styles.check}><img src="/check.svg" alt="" /></div>
    </div>
  )
}

DisplayItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  display: PropTypes.func,
}

const AmountDisplay = ({ value, label, display, style }) => {
  if (!value) {
    return null
  }

  const amountStyle = styles.value + (style ? ` ${style}` : '')

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={amountStyle}>{`$${display(value)}`}</div>
    </div>
  )
}

AmountDisplay.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  display: PropTypes.func,
  style: PropTypes.string,
}

export default function Summary() {
  const storage = typeof window !== 'undefined' ? window.sessionStorage : {};
  const key = process.env.GATSBY_SECRET;
  const context = useContext(Context)

  const { tagRenewalInfoApi } = useSiteConfig();
  const handleError = usePostError()

  async function handleNext() {
    const transactionId = getTransactionId()
    window.sessionStorage.setItem('mcn-form-path', 'renew-tag')

    const data = {
      storage: { ...storage, transactionId },
      key
    };

    const { setCurrent } = context

    try {
      const response = await axios.post(tagRenewalInfoApi, data)

      if (response.data) {
        setCurrent(5)

      } else if (response.error) {
        throw new Error(response.error)
      }

    } catch (e) {
      handleError(e)
    }
  }

  function handleBack() {
    const setCurrent = context?.setCurrent

    setCurrent(3);
  }

  function formatNum(num) {
    return (Math.round(num * 100) / 100).toFixed(2);
  }

  function handleFees() {
    const fee = storage.Fee ? parseFloat(storage.Fee) : 0;
    const penalty = storage.Penalty ? parseFloat(storage.Penalty) : 0;
    const total = formatNum(fee + penalty);
    return total;
  }
  
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Summary</h2>
       
        <DisplayItem {...{ label: 'Citizen roll number', value: storage.CustomerRollNum }} />
        <DisplayItem {...{ label: 'Plate number', value: storage.Tag }} />
        <DisplayItem {...{
          label: 'Tag expiration',
          value: storage.ExpireDate,
          display: (val) => new Date(val).toDateString()
        }} />
        <DisplayItem {...{ label: 'VIN', value: storage.VIN }} />

        <div style={{height: '2.5vw'}}></div>

        <DisplayItem {...{ label: 'Email address', value: storage.email }} />
        <DisplayItem {...{ label: 'Street address', value: storage.Address1 }} />
        <DisplayItem {...{ label: 'Unit', value: storage.Address2 }} />
        <DisplayItem {...{ label: 'City', value: storage.City }} />
        <DisplayItem {...{ label: 'State', value: storage.State }} />
        <DisplayItem {...{ label: 'ZIP', value: storage.Zip }} />
        <DisplayItem {...{ label: 'Phone', value: storage.phone }} />
      
        <div className={styles.fees}>
          <AmountDisplay {...{
            value: storage.Fee,
            display: (val) => formatNum(val),
            label: 'Fees'
          }} />

          <AmountDisplay {...{
            value: storage.Penalty,
            display: (val) => formatNum(val),
            label: 'Penalty'
          }} />
         
          <hr className={styles.line}></hr>
        
          <AmountDisplay {...{
            value: storage.Fee || storage.Penalty,
            display: () => handleFees(),
            label: 'Total',
            style: styles.total
          }} />
        </div>

        <Buttons {...{
          handleBack,
          handleNext,
          backLabel: 'BACK',
          nextLabel: 'PAYMENT'
        }} />
      </div>
    </section>
  )
}
