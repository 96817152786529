import React, { useState, useContext } from 'react'
import styles from './citizenId.module.scss'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import { Context } from '../context/provider'
import { useSiteConfig } from '../layout/getSiteConfig'
import Buttons from '../buttons/buttons'
import { getStorageItem } from '../../utilities/getStorageItem'
import { usePostError } from '../../utilities/usePostError'
import FileUpload from '../fileUpload/fileUpload'
import ErrorMessage from '../errorMessage/errorMessage'
import Input from '../input/input'
import Checkbox from '../checkbox/checkbox'

export default function CitizenId() {
  const [roll, setRoll] = useState(getStorageItem('CustomerRollNum'))
  const [vin, setVin] = useState(getStorageItem('VIN'))
  const [plate, setPlate] = useState(getStorageItem('Tag'))
  const [findVin, showFindVin] = useState(false)
  const [attachment, setAttachment] = useState(null)
  const [fileName, setFileName] = useState(getStorageItem('filename'))
  const [submit, setSubmit] = useState(false)
  const [message, setMessage] = useState('')
  const [apiMessage, setApiMessage] = useState('')
  const [loader, setLoader] = useState(false)
  const [travelTrailer, setTravelTrailer] = useState(false)

  const context = useContext(Context)
  const { citizenApi, uploadApi } = useSiteConfig()
  const useError = usePostError()

  const checkCitizenship = async () => {
    let valid = true

    const data = { roll, vin, plate: plate.toUpperCase() }

    if (!(plate && roll && vin)) {
      return
    }

    const result = await axios.post(citizenApi, data)
    const response = result.data

    if (response.ErrorCode !== 0) {
      const defaultMessage = "There was a problem finding your information"
      setMessage(response.ErrorMessage ?? defaultMessage)
      setLoader(false)
      valid = false

    } else {
      Object.entries(response).forEach(([key, value]) => {
        window.sessionStorage.setItem(key, value)
      })
      window.sessionStorage.setItem('travelTrailer', travelTrailer)
    }

    return valid
  }

  const uploadAttachment = async () => {
    if (!(attachment && vin)) {
      return
    }

    const formData = new FormData()
    formData.set('file', attachment, fileName)
    formData.set('id', vin)
    const key = process.env.GATSBY_SECRET
    formData.set('key', key)

    await axios.post(uploadApi, formData)
    window.sessionStorage.setItem('filename', fileName)
  } 

  const getVehicleInfo = async () => {
    const result = await axios.get(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}*BA?format=json`)
    
    if (!(result && result.data && result.data.Results)) {
      return
    }

    const variables = ["Model Year", "Make", "Model", "Body Class"]

    result.data.Results.forEach(result => {
      if (variables.includes(result.Variable) && result.Value)
        window.sessionStorage.setItem(result.Variable, result.Value)
    })
  }

  const handleSubmit = async () => {
    const { setCurrent } = context

    setLoader(true)
    setSubmit(true)

    try {
      const validCitizen = await checkCitizenship()

      if (!validCitizen) {
        setLoader(false)
        return
      }

      await uploadAttachment()
      await getVehicleInfo()

      setLoader(false)
      setCurrent(1)

    } catch(e) {
      setLoader(false)
      setApiMessage('There seems to be a problem with our server. Please try again later.')
      await useError(e)
    }
  }

  function handleChange(e) {
    const value = e.target.value
    const name = e.target.name
    if (name === 'roll')
      setRoll(value)
    else if (name === 'vin') {
      setVin(value)
    }
    else if (name === 'plate')
      setPlate(value)
  }

  function handleCheck(e) {
    const value = e.target.checked
    setTravelTrailer(value)
  }

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Citizen / vehicle identification</h2>
        <form onSubmit={e => e.preventDefault()}>
          <Input {...{
            label: 'Citizen roll number',
            name: 'roll',
            value: roll,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !roll]
          }} />

          <Input {...{
            label: 'Vehicle VIN',
            name: 'vin',
            value: vin,
            classname: styles.correct,
            xStyle: 'right',
            handleChange,
            invalid: [submit, !vin]
          }}>
            <small onMouseDown={() => showFindVin(!findVin)} className={styles.whereVin}>
              Where is my VIN?
            </small><br></br>
            <small className={styles.findVin + ` ${findVin ? `${styles.active}` : ""}`}>
              The VIN is typically located on the front of the driver's side dashboard by the windshield, and may best be viewed through the windshield.
            </small>
          </Input>
          
          <br></br>

          <Input {...{
            label: 'Vehicle Plate Number',
            name: 'plate',
            value: plate,
            xStyle: 'left',
            handleChange,
            invalid: [submit, !plate]
          }} />

          <Checkbox {...{
            label: 'If this is a travel trailer check here',
            handleCheck,
            checked: travelTrailer
          }} />

          <ErrorMessage message={message}/>

          <ErrorMessage message={apiMessage}/>

          <p className={styles.uploadMessage}>
            If you are renewing a commercial tag, farm tags, or other, please attach a photo of the required documentation, such as proof of citizenship, agricultural exemption card, Schedule C, K or S, etc.
          </p>

          <FileUpload {...{
            label: 'Attach files',
            setAttachment,
            setFileName,
            fileName,
            sessionKey: 'filename',
            name: 'upload'
          }} />
        </form>

        <Buttons {...{
          handleBack: () => context?.setCurrent(''),
          backLabel: 'BACK',
          handleNext: handleSubmit,
          nextLabel: 'NEXT'
        }}>
          {loader && <div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>

      </div>
    </section>
  )
}
