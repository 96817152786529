import React, { useState, useEffect } from 'react'
import CitizenId from '../citizenId/citizenId'
import Insurance from "../insurance/insurance"
import Marker from '../marker/marker'
import PersonalInfo from '../personalInfo/personalInfo'
import Summary from '../summary/summary'
import VerifyInfo from '../verifyInfo/verifyInfo'
import Footer from "../footer/footer"
import Header from "../header/header"
import Home from '../tagRenewalHome/tagRenewalHome'
import { Context } from '../context/provider'
import { getStorageItem } from '../../utilities/getStorageItem'

export default function TagRenewal() {
  const [current, setCurrent] = useState(getStorageItem('mcn-form-filled') === 'true' ? 4 : '');
  
  useEffect(() => {
    if (typeof window != 'undefined') {
      window.scrollTo(0,0)
    }
  }, [current])

  useEffect(() => {
    if (typeof window != 'undefined' && window.location.search.includes('test=')) {
      const search = window.location.search
      const page = search.split('=')[1]
      setCurrent(parseInt(page))
    }
  }, [])

  const markerItems = [
    'Citizen/vehicle identification',
    'Verify information',
    'Insurance verification', 
    'Personal information',
    'Summary',
    'Payment'
  ]

  useEffect(() => {
    if (current === 5) {
      window.location.href = '/payment'
    }
  }, [current])

  return (
    <Context.Provider value={{ current, setCurrent }}>
      <Header title={parseInt(current) >= 0 ? 'Tag Renewal' : ''} />
      {parseInt(current) >= 0 && current <= 5 && <Marker items={markerItems} />}
      {current === '' && <Home />}
      {current === 0 && <CitizenId />}
      {current === 1 && <VerifyInfo />}
      {current === 2 && <Insurance />}
      {current === 3 && <PersonalInfo />}
      {current === 4 && <Summary />}
      <Footer />
    </Context.Provider>
  )
}
