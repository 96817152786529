import React, { useState, useContext } from 'react'
import styles from './verifyInfo.module.scss'
import axios from "axios"
import Loader from 'react-loader-spinner'
import { Context } from '../context/provider'
import { useSiteConfig } from '../layout/getSiteConfig'
import { getStorageItem } from '../../utilities/getStorageItem'
import { usePostError } from '../../utilities/usePostError'
import Buttons from '../buttons/buttons'
import PropTypes from 'prop-types'

const DisplayItem = ({ label, value, displayFunc }) => {
  if (!value) {
    return null
  }

  const displayVal = displayFunc ? displayFunc(value) : value

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{displayVal}</div>
      <div className={styles.check}><img src="/check.svg" alt="" /></div>
    </div>
  )
}

DisplayItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  displayFunc: PropTypes.func,
}

export default function VerifyInfo() {
  const entries = typeof window !== 'undefined' ? window.sessionStorage : 'undefined'
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState(false)
  const context = useContext(Context)
  const { insuranceApi } = useSiteConfig()
  const handleError = usePostError()

  const [travelTrailer] = useState(getStorageItem('travelTrailer'))

  function checkDate(date) {
    const expDate = new Date(date)
    const today = new Date()
    const difference = expDate.getTime() - today.getTime()
    return (difference / (1000 * 3600 * 24)) <= 31
  }

  function setStorageItem(key, value) {
    window.sessionStorage.setItem(key, value ? value : '')
  }

  function handleSuccess(result) {
    const items = [
      { key: 'naic', value: result.data.naic },
      { key: 'policy', value: result.data.policyNumber },
      { key: 'verified', value: true },
    ]

    items.forEach(({ key, value }) => setStorageItem(key, value))
  }

  function handleFail(result) {
    const items = [
      { key: 'responseCode', value: result.data.responseCode },
      { key: 'reasonCode', value: result.data.reasonCode },
      { key: 'verified', value: false },
    ]

    items.forEach(({ key, value }) => setStorageItem(key, value))
  }

  async function handleNext() {
    try {
      const setCurrent = context?.setCurrent

      if (!checkDate(entries.ExpireDate)) {
        setMessage(true)
        return
      }

      if (travelTrailer === 'true') {
        setCurrent(3)
        return
      }
      
      const data = { vin: entries.VIN, key: process.env.GATSBY_SECRET }
      setLoader(true)

      const result = await axios.post(insuranceApi, data)

      setLoader(false)

      if (result.data.responseCode && result.data.responseCode !== "Confirmed") {
        handleFail(result)
      } else {
        handleSuccess(result)
      }

      setCurrent(2)
      
    } catch(e) {
      handleError(e)
    }
  }

  function handleBack() {
    const setCurrent = context?.setCurrent
    setCurrent(0)
  }
  
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Verify Citizen / Vehicle Information</h2>
        <DisplayItem {...{ label: 'Citizen roll number', value: entries.CustomerRollNum }} />
        <DisplayItem {...{ label: 'Plate number', value: entries.Tag }} />
        <DisplayItem {...{
          label: 'Tag expiration',
          value: entries.ExpireDate,
          displayFunc: (val) => new Date(val).toDateString()
        }} />
        <DisplayItem {...{ label: 'VIN', value: entries.VIN }} />
        <DisplayItem {...{ label: 'Model year', value: entries['Model Year'] }} />
        <DisplayItem {...{ label: 'Model', value: entries.Model }} />
        <DisplayItem {...{ label: 'Make', value: entries.Make }} />
        <DisplayItem {...{ label: 'Class', value: entries['Body Class'] }} />
     
        {message &&
        <p className={styles.message}>
          Renewals cannot be made more than 1 month in advance.
        </p>}

        <Buttons {...{
          handleBack,
          handleNext,
          backLabel: 'BACK',
          nextLabel: travelTrailer !== 'true' ? 'INSURANCE' : 'PERSONAL INFORMATION',
        }}>
          {loader &&<div className={styles.loader}>
            <Loader type="ThreeDots" color="#2BAF49" height={20} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}
