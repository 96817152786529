import React from "react"
import Layout from "../components/layout/layout"
import TagRenewal from "../components/tagRenewal/tagRenewal";

export default function RenewTag() {
  return (
    <Layout>
      <TagRenewal />
    </Layout>
  );
}
