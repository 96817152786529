import React, { useState, useContext, useEffect } from 'react'
import styles from './personalInfo.module.scss'
import Loader from 'react-loader-spinner'
import { Context } from '../context/provider'
import Buttons from '../buttons/buttons'
import { validateEmail } from '../../utilities/validateEmail'
import CheckInput from '../checkInput/checkInput'
import PropTypes from 'prop-types'

const Input = ({ name, label, value, invalid, handleChange}) => (<>
  <label htmlFor={name}>{label}</label>
  <input
    className={invalid.every(x => x) ? styles.invalid : ''}
    id={name}
    name={name}
    value={value}
    onChange={handleChange}
  />
</>)

Input.propTypes = {
  invalid: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
}

const DisplayItem = ({ value, label }) => {
  if (!value) {
    return null
  }

  return (
    <div className={styles.entry}>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  )
}

DisplayItem.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
}

const Checkbox = ({ label, name, checked, handleCheck }) => (
  <label className={styles.label}>{label}
    <input name={name} type='checkbox' checked={checked} onChange={handleCheck}/>
    <span className={styles.checkmark}></span>
  </label>
)

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  handleCheck: PropTypes.func,
}

const InvalidIcon = ({ style, invalid }) => (
  <div className={style + ` ${invalid.every(x => x) ? styles.active : ''}`}>x</div>
)

InvalidIcon.propTypes = {
  style: PropTypes.string,
  invalid: PropTypes.array
}


export default function PersonalInfo() {
  const context = useContext(Context)

  const storage = typeof window != 'undefined' ? window.sessionStorage : {}
  
  const { email, phone, Address1, Address2, City, State, Zip, travelTrailer } = storage

  const [emailVal, setEmailVal] = useState(email ?? '')
  const [confirmEmail, setConfirmEmail] = useState(email ?? '')
  const [phoneVal, setPhoneVal] = useState(phone ?? '')
  const [submit, setSubmit] = useState(false)

  const [loader, setLoader] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [correctAddress, setCorrectAddress] = useState(false)
  const [incorrectAddress, setIncorrectAddress] = useState(false)

  function handleBack() {
    const setCurrent = context?.setCurrent
    setCurrent(travelTrailer !== 'true' ? 2 : 1)
  }  
  
  function handleChange(e) {
    const value = e.target.value
    const name = e.target.name

    if (name === 'email')
      setEmailVal(value)
    
    if (name === 'confirm-email')
      setConfirmEmail(value)
    
    if (name === 'phone')
      setPhoneVal(value)
  }

  function handleCheck(e) {
    const value = e.target.checked
    const name = e.target.name

    if (name === 'yes') {
      setCorrectAddress(value)

      if (value && incorrectAddress) {
        setIncorrectAddress(false)
      }
    }
    
    if (name === 'no') {
      setIncorrectAddress(value)

      if (value && correctAddress) {
        setCorrectAddress(false)
      }
    }
  }

  useEffect(() => {
    window.sessionStorage.setItem('mcn-form-filled', false)
  }, [])

  const matched = confirmEmail === emailVal

  function handleSubmit() {
    const setCurrent = context?.setCurrent
    setLoader(true)
    setSubmit(true)
    const isEmail = validateEmail(emailVal, setValidEmail)

    const required = [emailVal, confirmEmail, matched, isEmail, Address1, City, State, Zip, correctAddress, !incorrectAddress]

    if (required.every(x => x)) {
      window.sessionStorage.setItem('mcn-form-filled', true)
      window.sessionStorage.setItem('email', emailVal)
      window.sessionStorage.setItem('phone', phoneVal)
      window.sessionStorage.setItem('current', 4)
      setCurrent(4)
    }
    setLoader(false)
  }

  const verifyAddressStyle = styles.checkAddress
    + `${submit && ((!incorrectAddress && !correctAddress) || incorrectAddress) ? ` ${styles.active}` : ''}`

  const emailCheckStyle = styles.check
    + `${emailVal && confirmEmail && emailVal === confirmEmail ? ` ${styles.active }` : ''}`

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2>Personal information</h2>

        <form onSubmit={e => e.preventDefault()}>
          <div className={styles.field}>
            <Input {...{
              value: emailVal,
              handleChange,
              invalid: [submit, (!emailVal || !validEmail)],
              name: 'email',
              label: 'Email address'
            }}/>
             
            <CheckInput {...{
              text: 'Please enter a valid email address',
              values: [submit, emailVal, !validEmail],
            }} />

            <InvalidIcon {...{
              style: styles.xLeft,
              invalid: [submit, (!emailVal || !validEmail)]
            }} />
          </div>

          <div className={styles.field}>
            <Input {...{
              value: confirmEmail,
              handleChange,
              invalid: [submit, (!confirmEmail || confirmEmail !== emailVal)],
              name: 'confirm-email',
              label: 'Confirm email address'
            }}/>

            <CheckInput {...{
              text: 'The email address entered does not match',
              values: [submit, confirmEmail, !matched],
            }} />

            <InvalidIcon {...{
              style: styles.xRight,
              invalid: [submit, (!confirmEmail || confirmEmail !== emailVal)]
            }} />

            <div className={emailCheckStyle}>
              <img src='/check.svg' alt='' />
            </div>
          </div>

          <div className={styles.field}>
            <Input {...{
              value: phoneVal,
              handleChange,
              invalid: [false],
              name: 'phone',
              label: 'Phone number'
            }}/>
          </div>

          <DisplayItem {...{ value: Address1, label: 'Street address' }} />
          <DisplayItem {...{ value: Address2, label: 'Unit' }} />
          <DisplayItem {...{ value: City, label: 'City' }} />
          <DisplayItem {...{ value: State, label: 'State' }} />
          <DisplayItem {...{ value: Zip, label: 'Zip' }} />

          <div className={styles.correctAddress}>
            <p>Is this your correct address?</p>

            <Checkbox {...{ label: 'Yes', name: 'yes', handleCheck, checked: correctAddress }} />
            <Checkbox {...{ label: 'No', name: 'no', handleCheck, checked: incorrectAddress }} />
          </div>

          <small className={verifyAddressStyle}>
            {(!incorrectAddress && !correctAddress) ? 'Please verify your address.' : 'Please contact our office.'}
          </small>
          
        </form>
       
        <Buttons {...{
          handleBack,
          handleNext: handleSubmit,
          backLabel: 'BACK',
          nextLabel: 'NEXT'
        }}>
          {loader &&
          <div className={styles.loader}>
            <Loader type='ThreeDots' color='#2BAF49' height={80} width={80} />
          </div>}
        </Buttons>
      </div>
    </section>
  )
}
